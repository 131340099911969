<template>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 my-10">
        <h1 class="my-5 text-white text-3xl font-bold">Learners</h1>
        <usersGrid :users="users"/>
    </div>
</template>

<script>
    import * as axios from '../axios-auth'
    import usersGrid from '../components/UsersGrid'
    export default {
        name: "Learners",
        props: ['communityOwner', 'communityName'],
        components: {
            usersGrid
        },
        data(){
            return {
                users: null,
            }
        },
        mounted(){
            this.getLearners()
        },
        computed: {
            communityNameForUrl(){
                return this.communityName.replace(/\s/g, "-")
            },
        },
        methods: {
            getLearners(){
                axios.jigo.get(`/v2/community/${this.communityOwner}/${this.communityNameForUrl}/stardusters`, {validateStatus: (status) => {
                // axios.jigit.get(`/repos/${this.communityOwner}/${this.communityName}/stargazers`, {validateStatus: (status) => {
                        return status < 500 }})
                    .then((resp) => {
                        if (resp.status === 200){
                            this.users = resp.data
                        } else {
                            console.error("Some error occurred trying to get the learners", resp)
                        }
                    }).catch((err) => {
                    console.error("Some error occurred trying to get the learners", err)
                })
            }
        },
    }
</script>

<style scoped>

</style>
